<template>
  <b-form-group
    :id="groupId"
    :label="$t(groupLabel)"
    :label-for="inputId"
    :description="$t(def.description)">

    <div
      v-if="def.type == 'readonly'"
      :id="inputId">
      {{ value }}
    </div>

    <b-form-file
      v-if="def.type == 'file'"
      :id="inputId"
      v-model="value"
      :state="valid"
      :required="def.required == true"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    />

    <b-form-input
      v-if="['text', 'email'].includes(def.type)"
      :id="inputId"
      v-model="value"
      :state="valid"
      :type="def.type"
      :required="def.required == true"
      :placeholder="$t(def.placeholder)"
    />

    <b-form-select
      v-if="def.type == 'select'"
      :id="inputId"
      v-model="value"
      :state="valid"
      :options="selectOptions"
      :required="def.required == true"
      :placeholder="$t(def.placeholder)"
    />

    <b-form-checkbox
      v-if="def.type == 'checkbox'"
      :id="inputId"
      v-model="value"
      :state="valid"
      type="checkbox"
      :required="def.required == true"
      value="1"
      unchecked-value="0"
    />

    <c-select
      v-if="def.type == 'v-select'"
      v-model="value"
      placeholder=" "
      :id="inputId"
      :options="selectOptions"
      :required="def.required == true"
    />

  </b-form-group>
</template>

<script>

  export default {
    name: 'FormFieldV3',
    components: {
    },
    data () {
      return {
      }
    },
    props: {
      form: Object,
      name: String
    },
    methods: {
      emitChange (val) {
        this.$emit('change', val, this.name)      
      }
    },
    computed: {
      groupId () { return this.name + '-group' },
      inputId () { return this.name + '-input' },
      groupLabel () { return this.def.label || this.name },
      valid () {
        if (!this.value && this.def.required) return false

        if (this.def.validation) return this.def.validation(this.value)

        return true
      },
      def () { 
        return this.form.fields[this.name] 
      },
      selectOptions () {
        if (Array.isArray(this.def.options)) return this.def.options
        return Object.entries(this.def.options).map(([k, v]) => {
          return {
            value: k,
            text: v, 
          }
        })
      },
      value: {
        get () { 
          let value = this.form.values[this.name]
          return value
        },
        set (val) {
          if (val.target) val = val.target.value
          this.emitChange(val)  
        },
      },
      vSelectRequired: function () {
        return this.value == undefined || this.value == ''
      }
    },
    mounted () {

    }
  }
</script>

<style lang="scss" scoped>

</style>
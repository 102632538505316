var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: {
        id: _vm.groupId,
        label: _vm.$t(_vm.groupLabel),
        "label-for": _vm.inputId,
        description: _vm.$t(_vm.def.description)
      }
    },
    [
      _vm.def.type == "readonly"
        ? _c("div", { attrs: { id: _vm.inputId } }, [
            _vm._v(" " + _vm._s(_vm.value) + " ")
          ])
        : _vm._e(),
      _vm.def.type == "file"
        ? _c("b-form-file", {
            attrs: {
              id: _vm.inputId,
              state: _vm.valid,
              required: _vm.def.required == true,
              placeholder: "Choose a file or drop it here...",
              "drop-placeholder": "Drop file here..."
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e(),
      ["text", "email"].includes(_vm.def.type)
        ? _c("b-form-input", {
            attrs: {
              id: _vm.inputId,
              state: _vm.valid,
              type: _vm.def.type,
              required: _vm.def.required == true,
              placeholder: _vm.$t(_vm.def.placeholder)
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e(),
      _vm.def.type == "select"
        ? _c("b-form-select", {
            attrs: {
              id: _vm.inputId,
              state: _vm.valid,
              options: _vm.selectOptions,
              required: _vm.def.required == true,
              placeholder: _vm.$t(_vm.def.placeholder)
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e(),
      _vm.def.type == "checkbox"
        ? _c("b-form-checkbox", {
            attrs: {
              id: _vm.inputId,
              state: _vm.valid,
              type: "checkbox",
              required: _vm.def.required == true,
              value: "1",
              "unchecked-value": "0"
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e(),
      _vm.def.type == "v-select"
        ? _c("c-select", {
            attrs: {
              placeholder: " ",
              id: _vm.inputId,
              options: _vm.selectOptions,
              required: _vm.def.required == true
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }